import * as React from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';

const IS_INTERCOME_ENABLED = true;

export default function InjectIntercom({ children }: React.PropsWithChildren) {
  const { boot, shutdown, update } = useIntercom();
  const { user } = useGetCurrentUser();

  React.useEffect(() => {
    if (!IS_INTERCOME_ENABLED) {
      return;
    }

    if (!boot || !shutdown || !update) {
      return;
    }

    boot();

    return () => {
      shutdown();
    };
  }, [boot, shutdown]);

  React.useEffect(() => {
    if (user && update) {
      update({
        userId: user.id,
        email: user.email,
        name: user.fullName,
        createdAt: user.createdAt && new Date(user.createdAt).getTime() / 1000,
      });
    }
  }, [user, update]);

  return <>{children}</>;
}
